import { FC, memo, useState } from "react";
import Image from "next/image";
import { StyledEntityImage } from "@/components/ui/EntityImage/Styled";
import { getImagePath } from "@/utils/common/helpers";
import { cssDesktop, cssMobile, cssTablet } from "./StyledBanner";
import { ViewModeBanner } from "../constants";
import { BannerPropsType } from "../types";
const BannerImage: FC<BannerPropsType & {
  view: ViewModeBanner;
}> = memo(({
  image: {
    tablet,
    mobile,
    desktop
  },
  view,
  priority,
  quality,
  unoptimized,
  loading,
  withFormatUrl = true
}) => {
  const [isError, setIsError] = useState(false);
  const objPositionDefault = desktop.objectPosition || "center";
  const objFitDefault = desktop.objectFit || "contain";
  const layoutDefault = desktop.layout || "fill";
  const widthDefault = desktop.width;
  const heightDefault = desktop.height;
  return <>
        {view === ViewModeBanner.DESKTOP && <StyledEntityImage className={cssDesktop}>
            <Image alt={""} src={isError ? "/images/banner-noimage.jpg" : withFormatUrl ? getImagePath(desktop.src.toString()) : desktop.src.toString()} objectPosition={objPositionDefault} objectFit={objFitDefault} layout={layoutDefault} width={widthDefault} height={heightDefault} priority={priority} quality={quality} unoptimized={unoptimized} loading={loading} onError={() => {
        setIsError(true);
      }} />
          </StyledEntityImage>}

        {view === ViewModeBanner.TABLET && <StyledEntityImage className={cssTablet}>
            <Image alt={""} src={withFormatUrl ? getImagePath((tablet?.src || desktop.src).toString()) : (tablet?.src || desktop.src).toString()} objectPosition={tablet?.objectPosition || objPositionDefault} objectFit={tablet?.objectFit || objFitDefault} width={tablet?.width || widthDefault} height={tablet?.height || heightDefault} priority={priority} quality={quality} layout={tablet?.layout || layoutDefault} unoptimized={unoptimized} loading={loading} />
          </StyledEntityImage>}

        {view === ViewModeBanner.MOBILE && <StyledEntityImage className={cssMobile}>
            <Image alt={""} src={withFormatUrl ? getImagePath((mobile?.src || desktop.src).toString()) : (mobile?.src || desktop.src).toString()} objectPosition={mobile?.objectPosition || objPositionDefault} objectFit={mobile?.objectFit || objFitDefault} width={mobile?.width || widthDefault} height={mobile?.height || heightDefault} priority={priority} layout={mobile?.layout || layoutDefault} quality={quality} unoptimized={unoptimized} loading={loading} />
          </StyledEntityImage>}
      </>;
});
BannerImage.displayName = "BannerImage";
export { BannerImage };