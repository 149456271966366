import { cx } from "@linaria/core";
import { FC } from "react";
import { useWindowSize } from "@/hooks/useWindowSize";
import { getBreakpointVal } from "@/styles/utils/Utils";
import { breakpoints } from "@/styles/utils/vars";
import { BannerImage } from "./BannerImage";
import { BannerRoute } from "./BannerRoute";
import { cssNotRounded, cssWithHover, StyledBanner, StyledInner } from "./StyledBanner";
import { ViewModeBanner } from "../constants";
import { BannerPropsType } from "../types";
const getViewMode = (width?: number): ViewModeBanner => {
  if (width === undefined) {
    return ViewModeBanner.DESKTOP;
  }
  return width <= getBreakpointVal(breakpoints.xs) ? ViewModeBanner.MOBILE : width <= getBreakpointVal(breakpoints.lg) ? ViewModeBanner.TABLET : ViewModeBanner.DESKTOP;
};
const Banner: FC<BannerPropsType> = ({
  url,
  withHover = false,
  className,
  image,
  quality = 100,
  priority,
  unoptimized,
  loading,
  rounded = true,
  ...props
}) => {
  const isModal = !!url && url[0] === "#";
  const path = isModal ? url.slice(1).toLowerCase() : url || undefined;
  const {
    width
  } = useWindowSize();
  const view = getViewMode(width);
  return <StyledBanner {...props} className={cx(className, withHover && cssWithHover, !rounded && cssNotRounded)}>
      <StyledInner>
        <BannerRoute isModal={isModal} path={path}>
          <BannerImage view={view} image={image} quality={quality} unoptimized={unoptimized} priority={priority} loading={loading} {...props} />
        </BannerRoute>
      </StyledInner>
    </StyledBanner>;
};
export { Banner };